import { motion } from 'framer-motion';
import React from 'react';

const ProjectFilter = ({ handleFilterClick, activeFilter }) => {
  
  // Animation des enfants et parents
  const childVariants = {
      visible: {opacity: 1, transition:{ duration:1, ease: "easeInOut" }},
      hidden: {opacity: 0}
  }
  const parentVariants = {
      visible: {transition: {staggerChildren: 0.2}},
      hidden: {transition: {staggerChildren: 0.2}}
  }

  return (
    <div className='mx-32'>
      <motion.ul variants={parentVariants} initial={'hidden'} animate={'visible'} className='mx-auto flex max-w-[1040px] justify-between md:justify-start xl:justify-center border-b border-white my-40'>
        <motion.li variants={childVariants} className={`transition duration-100 ${activeFilter === 'all' ? 'font-bold border-b-4 text-gold border-gold' : ''}`}>
          <button
            className='px-16 md:px-32 py-24 cursor-pointer focus:outline-none'
            onClick={() => handleFilterClick('all')}
          >
            All
          </button>
        </motion.li>
        <motion.li variants={childVariants} className={`transition duration-100 ${activeFilter === 'web' ? 'font-bold border-b-4 text-gold border-gold' : ''}`}>
          <button
            className='px-16 md:px-32 py-24 cursor-pointer focus:outline-none'
            onClick={() => handleFilterClick('web')}
          >
            Web
          </button>
        </motion.li>
        <motion.li variants={childVariants} className={`transition duration-100 ${activeFilter === 'transmedia' ? 'font-bold border-b-4 text-gold border-gold' : ''}`}>
          <button
            className='px-16 md:px-32 py-24 cursor-pointer focus:outline-none'
            onClick={() => handleFilterClick('transmedia')}
          >
            Transmedia
          </button>
        </motion.li>
        <motion.li variants={childVariants} className={`transition duration-100 ${activeFilter === 'other' ? 'font-bold border-b-4 text-gold border-gold' : ''}`}>
          <button
            className='px-16 md:px-32 py-24 cursor-pointer focus:outline-none'
            onClick={() => handleFilterClick('other')}
          >
            Other
          </button>
        </motion.li>
      </motion.ul>
    </div>
  );
};

export default ProjectFilter;
