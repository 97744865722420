import {ReactComponent as iconPhotoshop} from '../assets/icons/icon_photoshop.svg' ;
import {ReactComponent as iconIllustrator} from '../assets/icons/icon_illustrator.svg' ;
import {ReactComponent as iconPremierePro} from '../assets/icons/icon_premierepro.svg' ;
import {ReactComponent as iconFigma} from '../assets/icons/icon_figma-linear.svg' ;
import {ReactComponent as iconGoogle} from '../assets/icons/icon_google.svg' ;
import {ReactComponent as iconMusescore} from '../assets/icons/icon_musescore.svg' ;
import {ReactComponent as iconAirtable} from '../assets/icons/icon_airtable.svg' ;
import {ReactComponent as iconLightroom} from '../assets/icons/icon_lightroom.svg' ;
import {ReactComponent as iconVisualStudioCode} from '../assets/icons/icon_visualstudiocode.svg' ;
import {ReactComponent as iconNotion} from '../assets/icons/icon_notion.svg' ;
import {ReactComponent as iconMidjourney} from '../assets/icons/icon_midjourney.svg' ;
import {ReactComponent as iconAudition} from '../assets/icons/icon_audition.svg' ;
import {ReactComponent as iconHtml} from '../assets/icons/icon_html.svg' ;
import {ReactComponent as iconCss} from '../assets/icons/icon_css.svg' ;
import {ReactComponent as iconJavascript} from '../assets/icons/icon_javascript.svg' ;
import {ReactComponent as iconReact} from '../assets/icons/icon_react.svg' ;
import {ReactComponent as iconTailwind} from '../assets/icons/icon_tailwind.svg' ;
import {ReactComponent as iconMedium} from '../assets/icons/icon_medium.svg' ;
import {ReactComponent as iconPhp} from '../assets/icons/icon_php.svg' ;
import {ReactComponent as iconWordpress} from '../assets/icons/icon_wordpress.svg' ;

const technologiesIcons = [
    {name: "Photoshop", icon: iconPhotoshop},
    {name: "Illustrator", icon: iconIllustrator},
    {name: "Premiere Pro", icon: iconPremierePro},
    {name: "Lightroom", icon: iconLightroom},
    {name: "Audition", icon: iconAudition},
    {name: "Figma", icon: iconFigma},
    {name: "Airtable", icon: iconAirtable},
    {name: "Google Suite", icon: iconGoogle},
    {name: "Medium", icon: iconMedium},
    {name: "Musescore", icon: iconMusescore},
    {name: "Notion", icon: iconNotion},
    {name: "Midjourney", icon: iconMidjourney},
    {name: "Visual Studio Code", icon: iconVisualStudioCode},
    {name: "HTML", icon: iconHtml},
    {name: "CSS", icon: iconCss},
    {name: "Javascript", icon: iconJavascript},
    {name: "PHP", icon: iconPhp},
    {name: "Wordpress", icon: iconWordpress},
    {name: "ReactJS", icon: iconReact},
    {name: "TailwindCSS", icon: iconTailwind},
  ];

export default technologiesIcons