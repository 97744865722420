import React from 'react'
import ProjectBanner from '../components/ProjectBanner'
import Footer from '../components/Footer'
import SectionProject from '../components/SectionProjects'
import projects from '../../data/projects'
import linksIcons from '../../data/linksIcons'
import ProjectPageContent from '../components/ProjectPageContent'
import projectsContent from '../../data/projectsContent'
import { Helmet } from 'react-helmet-async';

const ProjectPage = () => {

    // identifier le projet
    const currentUrl = document.location.href ;
    const idPosition = currentUrl.lastIndexOf('/') ;
    const projectId = currentUrl.substring(idPosition + 1);

    //afficher d'autres projets similaires
    const currentProject = projects.find(project => projectId === project.id);
    const similarProjects = projects.filter((project) => project.filters[0] === currentProject.filters[0] && project.id !== currentProject.id);
    const filteredProjects = similarProjects.slice(0, 3);

    // Afficher tous les liens du projet
    const projectLinks = currentProject.links ? Object.keys(currentProject.links).map((linkType) => {

        const matchedIcon = linksIcons.find(icon => icon.name.toLowerCase() === currentProject.links[linkType].type);
        const CurrentIcon = matchedIcon ? matchedIcon.icon : null;
    
        return (
            <li key={linkType}>
                <a href={currentProject.links[linkType].url} target="_blank" rel="noopener noreferrer" className='inline-block text-sm rounded-full py-4 px-16 bg-gray-200 font-semibold hover:bg-white text-grey-dark transition duration-300'>
                    {CurrentIcon && <CurrentIcon className='h-full inline mr-8 fill-current' />}<span>{currentProject.links[linkType].text}</span>
                </a>
            </li>
        );
    }) : null;
    
    const SectionContent = () => {
        const isContentExist = projectsContent.find(project => currentProject.id === project.id);
        if (!isContentExist) {
            return null;
        } else {
            return (
                <div className='gradient-grey-bg pb-160 px-40 relative z-10'>
                    <div className='max-w-[960px] mt-80 mx-auto text-white leading-relaxed'>
                        <ProjectPageContent currentProject={currentProject} />
                        <ul className='flex flex-wrap justify-center gap-16 mt-80 md:mt-160'>{projectLinks}</ul>
                    </div>
                </div>
            )
        }
    }

    const pageTitle = "William Blanchy | " + currentProject.name ;

    return (
        <div className='bg-grey-dark'>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <ProjectBanner currentProject={currentProject} projectLinks={projectLinks} />
            <SectionContent />
            
            <SectionProject titleContent={"Similar Projects"} titleTextAlign={"center"} filteredProjects={filteredProjects} />
            <Footer />
        </div>
    )
}

export default ProjectPage