import React from 'react';
import { forwardRef } from 'react';
import { motion } from 'framer-motion';

const H2 = forwardRef(({ content, color="white", textAlign }, ref) => {
  const className = `font-serif text-4xl md:text-5xl text-${color} mb-24`+ (textAlign === "center" ? " text-center" : " text-left");
  return (
    <h2 className={className} ref={ref}>{content}</h2>
  );
})

const MotionH2 = motion(H2)

export default MotionH2;