import React from 'react';
import H1 from '../components/H1';
import Navbar from '../components/Navbar'
import MouseScroll from './MouseScroll';
import { motion } from 'framer-motion'

const Hero = () => {

  const childVariants = {
    visible: {opacity: 1, y: 0, transition:{duration:0.5}},
    hidden: {opacity: 0, y: 32}
  }
  const parentVariants = {
    visible: {transition: {staggerChildren: 0.2}},
    hidden: {transition: {staggerChildren: 0.2}}
  }


    function getOffset (el) {
      const rect = el.getBoundingClientRect()
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      }
    }
  
  const handleClick = () => {
    const section = document.querySelector('#section-contact')
    const sectionPosition = getOffset(section)
    window.scrollTo({
      top: sectionPosition.top,
      behavior: 'smooth'
    })
  }

  return (
    <div className='pb-40 xl:h-screen max-h-[900px] relative overflow-hidden'>
      <div className='absolute inset-0 z-0 bg-bgHome w-full h-full bg-cover bg-top bg-no-repeat parallax-content'></div>
      <div className='absolute inset-0 z-10 w-full h-full custom-bg'></div>
      <motion.div className='absolute inset-0 z-20 max-w-[1800px] xl:bg-myself bg-no-repeat bg-contain bg-right-bottom' initial={{opacity:0, x:80}} animate={{opacity:1, x:0, transition:{duration:1.5}}}></motion.div>
      <div className='relative z-30 h-full'>
        <Navbar bgLight={true} />
        <div className='px-16 m-auto max-w-[440px] xl:max-w-[1280px] flex flex-col justify-center'>
            <motion.div className='mt-24 md:mt-80 xl:ml-80 max-w-[440px] text-left' variants={parentVariants} initial={'hidden'} animate={'visible'}>
                <motion.p className='text-4xl xl:text-5xl font-serif' variants={childVariants}>Hello there,</motion.p>
                <H1 
                bgLight={true}
                content="I'm "
                contenthd="William."
                variants={childVariants}
                />
                <motion.p className='text-xl font-semibold mt-16 mb-24 pl-16 xl:pl-24 border-l-4 border-gold-dark' variants={childVariants}>
                    A Belgian Transmedia Architect specialized in UX/UI Design, Front-End Development and Digital Communication.
                </motion.p>
                <motion.p variants={childVariants}>
                    Passionate about creativity and eager to deliver exceptional user experiences, I am keen to apply my skills to dynamic projects and collaborate with like-minded professionals. Please, feel free to reach me out to discuss potential opportunities or to learn more about my work.
                </motion.p>
                <motion.ul className='w-max mx-auto md:mx-0 mt-32 flex flex-col items-center md:flex-row' variants={childVariants}>
                    <li className='my-16 md:mr-24'><a target='_blank' rel='noreferrer' href='https://www.williamblanchy.be/resume/WilliamBlanchy_CurriculumVitaePro.pdf' className='pr-24 bg-iconLink bg-right bg-auto bg-no-repeat hover:underline'>Check my resume</a></li>
                    <li className='my-16 md:mr-24 font-light'>or</li>
                    <li className='my-16 md:mr-24 active:scale-110 ease-in-out duration-300'><button onClick={handleClick} className='text-gold-dark font-semibold py-8 px-24 border border-gold-dark rounded-full gradient cursor-pointer focus:outline-none hover:text-white transition ease-in-out duration-500'>Contact me</button></li>
                </motion.ul>
            </motion.div>
            <MouseScroll color={"#161616"} />
        </div>
      </div>
    </div>
  );
};

export default Hero;