import React from 'react';
import { Link } from 'react-router-dom';
import Url from '../../controllers/Url';

const Logo = ({ bgLight }) => {
    const classColor = bgLight? 'fill-gold' : 'fill-white'
    const classColor2 = bgLight? 'fill-grey-dark' : 'fill-white'
    return (
        <Link to={Url.home} aria-label="Go to Homepage">
            <svg className='h-40' id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <g>
                <path className={classColor2} d="M41.25,158.08c0-7.33-1.01-12.75-3.02-16.27-2.01-3.52-6.03-5.28-12.07-5.28h-6.19v-4.96h59c39.8,0,59.7,12.57,59.7,37.72,0,6.76-2.37,12.9-7.11,18.43-4.74,5.53-11.85,9.81-21.34,12.83,13.22,1.87,22.49,5.71,27.8,11.53,5.32,5.82,7.97,13.4,7.97,22.74,0,14.66-5.79,24.82-17.35,30.5-11.57,5.68-25.97,8.51-43.21,8.51l-44.18-.37v-115.37Zm40.95,38.8c9.34,0,17.24-2.48,23.71-7.44,6.47-4.96,9.7-12.03,9.7-21.23s-3.77-16.85-11.32-21.66c-7.54-4.81-16.2-7.22-25.97-7.22-4.89,0-9.99,.72-15.3,2.16v55.39h19.18Zm29.53,61.42c6.9-5.17,10.34-12.64,10.34-22.41s-3.38-17.21-10.13-22.31c-6.76-5.1-16.67-7.65-29.74-7.65h-19.18v57.33c6.9,1.87,13.29,2.8,19.18,2.8,12.79,0,22.63-2.59,29.53-7.76Z"/>
                <path className={classColor} d="M215.8,158.14c-2.93-7.95-6-13.46-9.2-16.53-3.21-3.06-7.67-4.88-13.39-5.44v-4.18l7.74-.42c8.09,0,15.34,1.75,21.76,5.23,6.41,3.49,11.57,10.6,15.48,21.34l28.03,78.45,21.34-62.97-5.44-15.48c-3.07-7.95-6.21-13.46-9.42-16.53-3.21-3.06-7.67-4.88-13.39-5.44v-4.18c.7,0,1.74-.07,3.14-.21,1.39-.14,2.79-.21,4.18-.21,8.09,0,15.34,1.75,21.75,5.23,6.41,3.49,11.57,10.6,15.48,21.34l28.45,79.49,39.79-118.47,6.84,7.48-49.98,147.17h-5.23l-31.17-86.61-29.71,86.61h-5.44l-41.63-115.68Z"/>
                <path className={classColor2} d="M175.25,215.41l-37.07-55.82c-5.17-7.47-9.99-13.18-14.44-17.14-4.46-3.95-9.84-4.12-16.16-4.55l-28.1-6.27,48.57-.06c7.33,0,13.54,2.16,18.64,6.47,5.1,4.31,9.95,10.06,14.55,17.24l30.6,47.42,44.4-71.12h10.78l-50,81.26v60.99h-21.77v-58.41Z"/>
            </g>
            <g>
                <path className={classColor} d="M200,13.95c104.1,0,189.16,82.84,192.85,186.05,.08-2.32,.18-4.63,.18-6.97C393.03,86.59,306.44,0,200,0S6.97,86.59,6.97,193.03c0,2.34,.09,4.65,.18,6.97C10.84,96.79,95.9,13.95,200,13.95Z"/>
                <path className={classColor} d="M200,379.08c94.9,0,172.11-77.21,172.11-172.11,0-2.34-.08-4.66-.18-6.97-3.68,91.68-79.37,165.13-171.93,165.13S31.75,291.68,28.07,200c-.09,2.32-.18,4.63-.18,6.97,0,94.9,77.21,172.11,172.11,172.11Z"/>
                <path className={classColor2} d="M200,34.87c92.56,0,168.25,73.46,171.93,165.13,.09-2.32,.18-4.63,.18-6.97,0-94.9-77.21-172.11-172.11-172.11S27.89,98.13,27.89,193.03c0,2.34,.08,4.66,.18,6.97C31.75,108.32,107.44,34.87,200,34.87Z"/>
                <path className={classColor2} d="M200,386.05c-104.1,0-189.16-82.84-192.85-186.05-.08,2.32-.18,4.63-.18,6.97,0,106.44,86.59,193.03,193.03,193.03s193.02-86.59,193.02-193.03c0-2.34-.09-4.65-.18-6.97-3.69,103.21-88.75,186.05-192.85,186.05Z"/>
            </g>
            </svg>
        </Link>
    )
}

export default Logo