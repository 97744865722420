import wx96Ta from '../assets/images/wx96-ta.webp'
import wx96Ca from '../assets/images/wx96-conceptart.webp'
import wx96Pres from '../assets/images/wx96-presentation.jpg'
import swhLogo from '../assets/images/swh-logo.webp'
import swhPoster from '../assets/images/swh-poster.png'
import melissaMoodboard from '../assets/images/melissa-moodboard.png'
import melissaTa from '../assets/images/melissa-ta.jpg'

const projectsContent = [
    
    {
        id: "wx96",
        content: {
            section_1: {
                title: "When fiction meets reality",
                subtitle_1: "Synopsis",
                paragraph: "As Arnaud moves into his new apartment, he discovers two strange VHS tapes hidden behind the refrigerator. Sharing his adventures on Twitter, he brings to light a handful of increasingly bizarre archives: cryptic videos, Reddit messages from someone named Binary2404, senseless letters... Through this mix of information driving him mad, Arnaud gradually plunges into a world far too mysterious and dangerous for him.",
                subtitle_2: "Concept Art",
                image: {
                    url: wx96Ca,
                    alt: "Concept Art of the project in the style of a VHS cover"
                }
            },
            section_2: {
                title: "The genesis of the project",
                paragraph: "Urban legends, creepypastas, and ARGs fueled the inspiration behind WX-96. Our team's passion for horror narratives aligns with French speaker influencers like Feldup, LeGrandJD, and Squeezie, who captivate their audience with mysteries and thrills. The main motivations which pushed us to undertake this project are:",
                list: [
                    "We were a team of horror enthusiasts;",
                    "We noticed a scarcity of French urban legends;",
                    'The desire to build an engaged community, akin to "The Backrooms" (2019), "Ted the Caver" (2001), "The Blair Witch Project" (1999), and "SCP Foundation" (2006—).'
                ]
            },
            section_3: {
                title: "The methodology unveiled",
                subtitle_1: "1. Weaving the Narrative Tapestry of Urban Legend:",
                paragraph_1: "Creation of impactful symbolic elements to influence the real world:",
                list_1: [
                    "a symbol for the Whispering Church;",
                    'the sacred book "The Orb and the Man", written by Raphael;',
                    "the photos taken and published by Arnaud on Twitter;",
                    "etc."
                ],
                paragraph_2: "Sustaining audience interest over time, encouraging participant curiosity:",
                list_2: [
                    "create gaps in the narrative in order to spark theories and interpretations;",
                    "use the Whispering Church newsletter to share content over the time;",
                    "etc."
                ],
                subtitle_2: "2. Erecting the Transmedia Architecture:",
                image: {
                    url: wx96Ta,
                    alt: "Transmedia architecture of the project"
                },
                paragraph_3: "WX-96 is a transmedia horror experience shaped by participant contributions. They actively enrich the narrative by sharing their stories and creations, ensuring a dynamic and enduring storyline. Beyond digital screens, the project extends into the real world, utilizing specific signals such as maps, videos, and images captured in the Belgian city of Namur. Namur, chosen for its familiarity and student population, provides an authentic backdrop, blurring the boundaries between fiction and reality.",
                paragraph_4: "WX-96's narrative unfolds through multiple temporal dimensions, incorporating concrete clues from different time frames. For instance, a 1996 TV bulletin becomes a puzzle piece, influencing Arnaud's narrative arc in 2023. This innovative approach invites participants on a journey where transmedia horror is brought to life through a collaborative effort, seamlessly blending community creativity and storytelling mastery. The focus is on an exploration of the unknown, offering a unique and engaging narrative experience.",
                subtitle_3: "3. Crafting the Media that Supports the Architecture:",
                paragraph_5: "Like the two previous stages, it was undertaken by the team itself, without seeking assistance from external professionals. This approach was favored because it aligns with the essence of an urban legend: an authentic project, crafted by enthusiasts for enthusiasts, with no profit-oriented objectives."
            },
            section_4 : {
                title: "Think outside the box to present the project",
                paragraph_1: "The presentation of WX-96, as an immersive and narrative horror experience, held paramount significance in effectively conveying its multifaceted elements to the jury. Our objective was to immerse the jury members fully in the narrative during the presentation. To achieve this, we embraced an innovative approach by structuring the presentation as an escape room-style game, providing the jurors with a distinctive and immersive encounter. Throughout the gripping 10-minute game, participants confronted a pivotal dilemma, compelling them to make a decisive choice regarding the fate of the two protagonists in our narrative.",
                paragraphe_2: "In our pursuit of a seamlessly aligned atmosphere with the universe of our project, we meticulously sealed all windows with aged newspapers and cardboard, casting the room into an enveloping darkness that exuded oppression. Project team members underwent a metamorphosis, assuming the roles of mysterious characters draped in long black cloaks and masks, embodying the personas of the Whispering Church. This immersive and theatrical approach was crafted to captivate the jury, aiming to impart an experience that would etch a lasting impression of our project in their minds.",
                image: {
                    url: wx96Pres,
                    alt: "Overview of the room's decor during the presentation of our project to the jury"
                }
            }
        }
    },
    {
        id: "masterthesis",
        content: {
            section_1: {
                title: "Transmedia and Board Games: An Analysis of Current Trends",
                subtitle_1: "Introduction",
                paragraph_1: `For thousands of years, board games have been a popular means of entertainment and socialization across the world. Over the centuries, they have evolved into games that we still know today, such as chess or Go, but it is in the past few decades that they have changed the most. As Vincent Berry and Samuel Coavoux mention in their articles (Berry, 2021), the advent of video games did not lead to a decrease in the popularity of board games; quite the contrary. This rise in popularity is partly due to the diversification of the market, which has prompted the industry to offer richer and more varied games. Thus, a distinction has developed between so-called “German-style” board games, characterized by classic mechanics and the accumulation of victory points, and Anglo-Saxon board games, often referred to as "Ameritrash", which place greater emphasis on storytelling and immersion.`,
                paragraph_2: `It was also during this period that the concept of transmedia emerged, involving the use of different media to expand a universe and enhance storytelling. We can therefore assume that transmedia has similarly influenced the evolution of board games by offering new possibilities to game creators.`,
                paragraph_3: `This research aims to understand how transmedia has impacted the world of Francophone board games, focusing on current practices and emerging trends. More specifically, we will seek to determine how transmedia has influenced the design of board games and to what extent it is used to enhance storytelling.`,
                paragraph_4: `The first part of this dissertation will consist of a literature review on transmedia and board games, emphasizing several key concepts dependent on three fundamental aspects in the gaming field: storytelling, immersion, and emotions. We will also clarify our research question by clearly defining the concepts involved and identifying the categories of board games included in our study. Based on this review, we will formulate hypotheses to answer the research question posed earlier.`,
                paragraph_5: `The second part will be devoted to the method used to analyze the following three transmedia board games: The Lord of the Rings: The Card Game, Mansions of Madness: Second Edition, and A Game of Thrones: The Board Game. We will describe in detail the approach adopted to select these games, taking into account the perspectives of players and authors through a survey and two interviews. This part will culminate in the theoretical and practical analysis of these games using a grid specifically designed for the research.`,
                paragraph_6: `Finally, the conclusion of this dissertation will consist of a summary of our research and its main results. It will also propose potential avenues for future research and a brief connection with my final project, WX‑96.`
            }
        }
    },
    {
        id: "saint-walhere-hemptinne",
        content: {
            section_1: {
                title: "When Design and Creativity Meet Belgian Folklore",
                subtitle_1: "The logo",
                image: {
                    url: swhLogo,
                    alt: "Horizontal and vertical versions of the logo"
                },
                subtitle_2: "The poster",
                image_2: {
                    url: swhPoster,
                    alt: "Evolution of the poster in 3 different versions"
                },
            }
        }
    },
    {
        id: "melissa",
        content: {
            section_1: {
                title: "Beyond the Stars: A Playful Approach to Communicating Space Sustainability",
                subtitle_1: `Introduction to the MELiSSA Project`,
                paragraph_1: "The MELiSSA (Micro-Ecological Life Support System Alternative) project, initiated by the European Space Agency (ESA), is an ambitious research program aimed at developing a circular life support system capable of producing food, water, and oxygen from waste during space missions. This project is crucial for the realization of future long-duration missions, particularly the exploration of Mars. The ultimate goal is to ensure the self-sufficiency of astronauts, thereby reducing dependence on terrestrial supplies. We embarked on creating a transmedia campaign to demystify this complex and fascinating project, making it more accessible to the general public and fostering community engagement around this innovative initiative.",
                paragraph_2: "This campaign is part of a strategic communication effort aimed at reaching different segments of the population. By integrating educational and entertaining elements, we hope to raise awareness among both science and space enthusiasts and those curious about survival technologies in space. The MELiSSA project, with its ecological nature and potential terrestrial applications, offers a unique opportunity to link space advancements with current environmental issues.",
            },
            section_2: {
                title: "Worldbuilding & Lore",
                paragraph_1: "The narrative universe of our transmedia campaign spans nearly a century, from 2022 to 2112, offering a detailed and immersive vision of the evolution of the MELiSSA project and the colonization of Mars. We have developed a complex storyline, illustrated by moodboards, that integrates elements of science fiction while remaining grounded in scientific realities. This approach allows us to create a rich and credible world where every detail contributes to the audience's immersion.",
                subtitle_1: `Moodboard`,
                image: {
                    url: melissaMoodboard,
                    alt: "Several images that inspired us to create the visual aspect of the project."
                },
                paragraph_2: "The story begins in 2022, with the initial research of the MELiSSA project on Earth. As the years pass, this research culminates in the creation of a miniaturized recycling system enabling survival in space. By 2072, the installation of orbital stations and Martian bases marks the beginning of the golden age of Martian colonization. However, unexpected challenges arise, such as the destruction of the orbital station in 2080, plunging the Martian base into chaos. By 2112, Martian society has divided into three main factions:",
                list: [
                    "Atlas, explorers of the red planet;",
                    "Demeter, botanists and farmers;",
                    'Athena, technology specialists.'
                ],
                paragraph_3: "Meanwhile, a rebel group, the Children of Ares, contests the established order, adding a dimension of conflict and tension to our narrative.",
            },
            section_3: {
                title: "Development of a Transmedia Architecture",
                paragraph_1: "To tell the story of MELiSSA, we have chosen a transmedia approach, using a variety of media to reach a broad and diverse audience. Here are the main elements of our transmedia architecture:",
                image: {
                    url: melissaTa,
                    alt: "Visualization of the transmedia architecture and the 7 media that compose it."
                },
                subtitle_1: `Website`,
                paragraph_2: "The website is the main portal of our campaign. It is divided into two sections: one part dedicated to space sector professionals, featuring the current content of the MELiSSA Foundation website, and a new section aimed at the general public. The latter simplifies the project using immersive storytelling, placing the user in 2072 with space stations orbiting Mars. Interactive elements and hidden clues invite visitors to explore further and unlock bonuses in the associated video game.",
                subtitle_2: `3D Presentation Support`,
                paragraph_3: "At the biennial MELiSSA Foundation conferences, a 3D presentation support immerses participants in a space station in 2072. This immersive setting, created in low poly 3D isometric style, facilitates the understanding of scientific advancements while subtly integrating elements of our narrative. Visitors can interact with models representing the project's laboratories, enhancing the presentation's educational and entertaining aspects.",
                subtitle_3: `Video Game`,
                paragraph_4: "The video game is set in 2112, following an electromagnetic storm that ravaged Martian infrastructure. Players, as survivors, must gather resources, rebuild the MELiSSA Life Support System, and interact with the three factions. The game offers a rich experience in choices and community interactions, allowing players to fully immerse themselves in our universe. It is designed to be played on smartphones and computers, ensuring maximum accessibility.",
                subtitle_4: `Twitch Live Streams`,
                paragraph_5: "To reach a young and dynamic audience, we collaborate with popular streamers on Twitch. Two types of live streams are planned: gaming sessions to promote the video game and educational live streams to discuss the scientific advancements of the MELiSSA project. These live streams create a direct link with the community, allowing real-time interaction and answering the audience's questions. Contests and special events increase viewer engagement and encourage them to learn more about the project.",
                subtitle_5: `VR Experience`,
                paragraph_6: "The virtual reality experience, available in science museums and amusement parks across Europe, immerses visitors in 2080 aboard the space station. This immersive experience uses advanced technologies to offer a realistic simulation of the challenges and wonders of life in space. Visitors can explore the station, interact with life support systems, and understand the scientific principles behind the MELiSSA project.",
                subtitle_6: `Social Media`,
                paragraph_7: "A social media content campaign ensures a continuous and interactive presence. Videos, infographics, interviews, and articles are regularly published to maintain public interest and inform them of the project's latest advancements. Social media also serves as a platform to gather feedback, organize online events, and mobilize the community around challenges and awareness campaigns.",
                paragraph_8: "This transmedia architecture, combining various media and formats, allows us to reach a wide and varied audience, spark interest in the MELiSSA project, and build an engaged community around this pioneering initiative.",
            },
        }
    },
    {
        id: "avatar",
        content: {
            section_1: {
                title: "Decoding Avatar: Transmedia Brilliance & Fan Engagement",
                subtitle_1: "Introduction",
                paragraph_1: `Welcome to my in-depth analysis of the transmedia universe of "Avatar: The Last Airbender." This project delves into the rich and expansive world created by Michael Dante DiMartino and Bryan Konietzko. Produced by Nickelodeon in 2005, "Avatar" has captivated audiences with its intricate storytelling, memorable characters, and profound themes. In this analysis, I explore how the series has extended beyond its original medium, becoming a comprehensive transmedia phenomenon.`,
                paragraph_2: `Transmedia storytelling, as defined by media scholars like Henry Jenkins and Sarah Sepulchre, involves the extension of a narrative across multiple platforms, each contributing uniquely to the overall story. "Avatar: The Last Airbender" exemplifies this concept through its various adaptations and extensions, including comics, a sequel series, novels, video games, and more. Each of these mediums expands on the lore and characters introduced in the original series, providing fans with a deeper and more immersive experience.`,
                paragraph_3: `One of the most compelling aspects of "Avatar" is its ability to engage fans emotionally and creatively. The series has inspired an outpouring of fan-generated content, from fan art and fiction to cosplay and online forums. This active participation not only demonstrates the series' lasting impact but also enriches the transmedia narrative by incorporating diverse perspectives and interpretations. In my analysis, I highlight how this fan engagement has played a crucial role in the series' enduring popularity.`,
                paragraph_4: `Moreover, the creators of "Avatar" have meticulously crafted an elaborate and coherent universe. From the distinct bending styles and cultures of the four nations to the deeply rooted mythology and spiritual elements, every aspect of the world-building is thoughtfully designed. This attention to detail is evident in the series' seamless integration of martial arts, philosophy, and real-world cultural influences, which adds layers of authenticity and depth to the narrative.`,
                paragraph_5: `In conclusion, this analysis aims to provide a comprehensive look at how "Avatar: The Last Airbender" has evolved into a transmedia success story. By examining the series through the lenses of intertextuality, fan engagement, and narrative expansion, I hope to shed light on the strategies that have made "Avatar" a beloved and influential work. Whether you are a long-time fan or new to the series, I invite you to explore the fascinating world of "Avatar" and discover the magic that continues to captivate audiences around the globe.`,
            }
        }
    },
    {
        id: "theboys",
        content: {
            section_1: {
                title: "The Boys Transformed: A Transmedia Approach to Promotional Storytelling",
                paragraph: "On going redaction..."
            }
        }
    }
]

export default projectsContent