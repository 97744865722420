import React from 'react';
import { forwardRef } from 'react';
import { motion } from 'framer-motion'

const H1 = forwardRef(({ content, contenthd, bgLight, textAlignCenter }, ref) => {

    const className = 'text-left font-serif text-7xl xl:text-9xl pb-8 ' + (textAlignCenter ? 'text-center' : 'text-left')
    const classb = className + (bgLight ? ' text-grey-dark' : ' text-white')
    const classContentHd = 'text-gold-dark infinite-color-change ' + (bgLight ? 'gradient-text-dark' : 'gradient-text-light')

    return (
      <h1 className={classb} ref={ref}>
        {content}
        <span className={classContentHd}>
            {contenthd}
        </span>
      </h1>
    )
  })

  const MotionH1 = motion(H1)
  
  export default MotionH1