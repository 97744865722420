import {ReactComponent as iconWebsite} from '../assets/icons/icon_website.svg' ;
import {ReactComponent as iconMedium} from '../assets/icons/icon_medium-link.svg' ;
import {ReactComponent as iconFigma} from '../assets/icons/icon_figma.svg' ;
import {ReactComponent as iconYoutube} from '../assets/icons/icon_youtube.svg' ;
import {ReactComponent as iconFolder} from '../assets/icons/icon_folder.svg' ;

const linksIcons = [
    {name: "Medium", icon: iconMedium},
    {name: "Website", icon: iconWebsite},
    {name: "Figma", icon: iconFigma},
    {name: "Youtube", icon: iconYoutube},
    {name: "File", icon: iconFolder},
  ];

export default linksIcons