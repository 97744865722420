import React from 'react';
import Logo from '../components/Logo'
import Instagram from '../components/Instagram'
import Linkedin from '../components/Linkedin'
import { NavLink } from 'react-router-dom';
import Url from '../../controllers/Url'

const Navbar = ({ bgLight }) => {


    const className = 'flex ml-16 xl:ml-64 uppercase text-sm ' + (bgLight ? 'text-grey-dark' : 'text-white')

    return (
        <div className='static px-16 m-auto flex justify-between items-center py-16 xl:px-40 max-w-[1280px]'>
            <div className='flex items-center'>
                <Logo bgLight={bgLight} />
                <ul className={className}>
                    <li className='px-16'><NavLink to={Url.home} className={({ isActive }) => isActive? "font-bold": 'hover:underline'}>Home</NavLink></li>
                    <li className='px-16'><NavLink to={Url.portfolio} className={({ isActive }) => isActive? "font-bold": 'hover:underline'}>Portfolio</NavLink></li>
                </ul>
            </div>
            <ul className='flex justify-end'>
                <li className='mr-16'><a target='_blank' rel='noreferrer' aria-label="Check my LinkedIn Profile" href='https://www.linkedin.com/in/william-blanchy-a6b857200/'><Linkedin bgLight={bgLight} /></a></li>
                <li><a target='_blank' rel='noreferrer' aria-label="Check my Instagram Profile" href='https://www.instagram.com/willbchy/'><Instagram bgLight={bgLight} /></a></li>
            </ul>
        </div>
    )
}

export default Navbar