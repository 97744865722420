import React, { useEffect } from 'react';
import Hero from '../components/Hero'
import SectionProjects from '../components/SectionProjects';
import SectionContact from '../components/SectionContact';
import Footer from '../components/Footer';
import projects from '../../data/projects';
import { Helmet } from 'react-helmet-async';
import SectionAbout from '../components/SectionAbout';

const Home = () => {
    
    const filteredProjects = projects.filter((project) => project.featured === true)

    // Effet de parallaxe
    useEffect(() => {
        const handleScroll = () => {
          const parallaxContent = document.querySelector('.parallax-content');
          if (parallaxContent) {
            const scrollY = window.scrollY;
            const translateY = scrollY * 0.3;
            parallaxContent.style.transform = `translateY(${translateY}px)`;
          }
        };

        // Vérifier la largeur de l'écran avant d'attacher l'écouteur d'événements
        const isScreenWideEnough = window.innerWidth >= 900;
    
        if (isScreenWideEnough) {
          window.addEventListener('scroll', handleScroll);
        }
    
        // Nettoyer l'écouteur d'événements lors du démontage du composant
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return (
        <div className='bg-sand'>
          <Helmet>
            <title>William Blanchy | Transmedia Architect</title>
          </Helmet>
          <Hero />
          <div className='bg-grey-dark w-full text-white'>
            <SectionAbout />
            <SectionProjects titleContent={"Featured Projects"} titleTextAlign={"center"} filteredProjects={filteredProjects} />
            <SectionContact />
          </div>
          <Footer />
        </div>
    )
}

export default Home