import React from 'react';

const MouseScroll = ( { color } ) => {

    const className = 'hidden xl:block absolute transform translate-x-1/2 z-30 left-1/2 mouseAnimation bottom-40'

    return(
        <svg className={className} width="32" height="48" fill='none' viewBox="0 0 32 48" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="30" height="46" rx="15" stroke={color} strokeWidth="2"/>
            <ellipse cx="16" cy="15" rx="2" ry="4" fill={color}/>
        </svg>
    )
}

export default MouseScroll