import React from 'react';
import H2 from './H2';
import Linkedin from './Linkedin';
import Instagram from './Instagram';
import { motion } from 'framer-motion';


const SectionContact = () => {

    const childVariants = {
        visible: {opacity: 1, y: 0, transition:{duration:0.5}},
        hidden: {opacity: 0, y: 32}
      }
      const parentVariants = {
        visible: {transition: {staggerChildren: 0.2}},
        hidden: {transition: {staggerChildren: 0.2}}
      }

    return(
        <div id='section-contact' className='relative text-left bg-grey-dark'>
            <div className='px-16 py-40 xl:py-0 xl:flex max-w-[1280px] xl:mx-auto'>
                <motion.div className='flex flex-col items-center xl:block max-w-[400px] xl:m-80 mb-80 xl:mb-160 mx-auto' variants={parentVariants} initial={'hidden'} whileInView={'visible'} viewport={{once:true}}>
                    <H2 content='Reach me out' variants={childVariants} />
                    <motion.p variants={childVariants}>If you've enjoyed exploring my portfolio and are interested in the possibility of collaborating, feel free to reach out to me via email or by sending me a message on the social media. </motion.p>
                    <motion.a variants={childVariants} target='_blank' rel="noreferrer" href='mailto:william_blanchy@outlook.com' className='inline-block my-40 hover:underline font-serif text-xl'>william_blanchy@outlook.com</motion.a>
                    <motion.ul className='flex' variants={childVariants}>
                        <li className='mr-16'><a target='_blank' rel="noreferrer" aria-label="Check my LinkedIn Profile" href='https://www.linkedin.com/in/william-blanchy-a6b857200/'><Linkedin bgLight={false} /></a></li>
                        <li className='mr-16'><a target='_blank' rel="noreferrer" aria-label="Check my Instagram Profile" href='https://www.instagram.com/willbchy/'><Instagram bgLight={false} /></a></li>
                    </motion.ul>
                </motion.div>
                <div className='hidden xl:block xl:absolute left-1/2 w-1/2 h-full bg-bgContact bg-no-repeat bg-cover overflow-hidden'>
                    <svg className='hidden xl:block absolute h-1/2' viewBox="0 0 167 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M167 0H0V239.5L167 0Z" fill="#161616"/>
                    </svg>
                    <svg className='hidden xl:block absolute bottom-0 translate-y-1 h-1/2' viewBox="0 0 167 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M167 239.5L0 239.5L-2.09378e-05 0L167 239.5Z" fill="#161616"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default SectionContact