import React from 'react'
import { forwardRef } from 'react';
import { motion } from 'framer-motion';

const H3 = forwardRef(({ content }, ref) => {
  return <h3 ref={ref} className='text-gold font-light text-xl md:text-2xl font-sans mb-16 mt-24'>{content}</h3>
});

const MotionH3 = motion(H3)

export default MotionH3