import React from 'react';
import H2 from './H2';
import { Link } from 'react-router-dom';
import Url from '../../controllers/Url'
import ProjectList from './ProjectList';
import { motion } from 'framer-motion';

const SectionProjects = ({ titleContent, filteredProjects, titleTextAlign }) => {
    
  
    return (
      <div className='relative gradient-grey-bg pt-40 xl:pt-80 2xl:p-80 text-white pb-160 overflow-hidden'>
        <svg className='hidden xl:block absolute z-0 right-0 top-0' width="524" height="1048" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_f_74_8)">
          <circle cx="524" cy="524" r="284" fill="white" fillOpacity="0.08"/>
          </g>
          <defs>
          <filter id="filter0_f_74_8" x="0" y="0" width="1048" height="1048" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="120" result="effect1_foregroundBlur_74_8"/>
          </filter>
          </defs>
        </svg>
        <svg className='hidden xl:block absolute z-0 left-0 origin-center rotate-180 top-0' width="524" height="1048" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_f_74_8)">
          <circle cx="524" cy="524" r="284" fill="white" fillOpacity="0.08"/>
          </g>
          <defs>
          <filter id="filter0_f_74_8" x="0" y="0" width="1048" height="1048" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="120" result="effect1_foregroundBlur_74_8"/>
          </filter>
          </defs>
        </svg>
        <div className='m-auto w-full max-w-[1280px] relative z-10'>
          <H2 textAlign={titleTextAlign} color='white' content={titleContent} initial={{opacity: 0, y: -40, scale: 0.8}} whileInView={{opacity: 1, y: 0, scale: 1, transition:{duration:1}}} viewport={{once:true}} />
          <ProjectList filteredProjects={filteredProjects} activeFilter={'all'} />
          <div className='text-center'>
            <Link to={Url.portfolio} className='relative z-10 active:scale-110 ease-in-out duration-500 inline-block text-gold-light py-[10px] px-24 border border-gold-light rounded-full gradient-light hover:text-grey-dark font-semibold transition'>All&nbsp;projects</Link>
          </div>
        </div>
        <motion.div initial={{opacity:0}} whileInView={{opacity:1, transition:{duration:2}}} viewport={{once:true}} className='absolute z-0 top-0 left-0 w-full h-full bg-bgHexa bg-cover bg-center opacity-50'></motion.div>
      </div>
    );
  }
  
  export default SectionProjects;