import React from 'react';
import projectsContent from '../../data/projectsContent';
import MotionH2 from './H2';
import MotionH3 from './H3';
import { motion } from 'framer-motion';

const ProjectPageContent = ({ currentProject }) => {

    // Animation
    const childVariants = {
        visible: {opacity: 1, y: -24, transition:{duration:0.5}},
        hidden: {opacity: 0, y:0}
    }

  
    // Trouver le bon projet dans la base de données de contenu
  const currentProjectContent = projectsContent.find(pC => pC.id === currentProject.id);

  const renderSection = (sectionData) => {
    return Object.entries(sectionData).map(([key, value]) => {
      
        if (/^subtitle/.test(key)) {
        // Si la clé commence par "subtitle", il s'agit d'un sous-titre
        return <MotionH3 key={key} variants={childVariants} content={value} />;
      } else if (key === 'title') {
        // Si la clé est 'title', il s'agit d'un titre
        return <MotionH2 key={key} variants={childVariants} content={value} />;
      } else if (Array.isArray(value)) {
        // Si la valeur est un tableau, il s'agit probablement d'une liste
        return (
          <ul className='list-disc pl-32 mt-16' key={key}>
            {value.map((item, index) => (
              <motion.li variants={childVariants} key={index}>{item}</motion.li>
            ))}
          </ul>
        );
      } else if (typeof value === 'object') {
        // Si la valeur est un objet, vérifie si c'est une image
        if (value.url && value.alt) {
          // Si c'est une image, rend l'élément <img>
          return <motion.img variants={childVariants} key={key} src={value.url} alt={value.alt} className='w-full mt-24 mb-40' />;
        } else {
          // Sinon, cela pourrait être une sous-section
          return <div key={key}>{renderSection(value)}</div>;
        }
      } else {
        // Sinon, affiche la valeur comme un paragraphe
        return <motion.p variants={childVariants} className='my-16' key={key}>{value}</motion.p>;
      }
    });
  };

  return (
    <div className='text-white'>
      {Object.entries(currentProjectContent.content).map(([sectionKey, sectionData]) => (
        <motion.div initial={"hidden"} whileInView={"visible"} viewport={{ once:true }} key={sectionKey} className='mb-80'>
          {renderSection(sectionData)}
        </motion.div>
      ))}
    </div>
  );
};

export default ProjectPageContent;