import React from 'react';

const Footer = () => {
    return(
        <div className='px-16 w-full bg-[#0E0E0E] text-white text-center font-light text-sm py-8'>
            <p>© 2024 - Designed & Developed by William Blanchy - All Rights Reserved.</p>
        </div>
    )
}

export default Footer