import './App.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ScrollToTop from './views/components/ScrollToTop'
import { Url } from './controllers/Url'
import Home from './views/pages/Home'
import Portfolio from './views/pages/Portfolio'
import projects from './data/projects'
import ProjectPage from './views/pages/ProjectPage'
import { HelmetProvider } from 'react-helmet-async';

function App() {

  

  return (
    <div className='text-base font-sans text-grey-dark'>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path={Url.home} element={<Home />} />
            <Route path={Url.portfolio} element={<Portfolio />} />
            {projects.map((project, index) => {
              return (
                <Route key={index} exact path={`/portfolio/` + project.id} element={<ProjectPage />} />
              )
            })}
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  )
}

export default App;
