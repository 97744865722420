import React from 'react'
import MotionH1 from './H1'
import Navbar from './Navbar'
import technologiesIcons from '../../data/technologiesIcons'
import { motion } from 'framer-motion'
import projectsContent from '../../data/projectsContent'


const ProjectBanner = ({ currentProject, projectLinks }) => {

    // Animation des enfants et parents
    const childVariants = {
        visible: {opacity: 1, x: 0, transition:{duration:0.5}},
        hidden: {opacity: 0, x: -24}
    }
    const parentVariants = {
        visible: {transition: {staggerChildren: 0.2}},
        hidden: {transition: {staggerChildren: 0.2}}
    }

    const keywords = currentProject.keywords.map((keyword, index) => (
        <li key={index} className='py-8 px-16 border border-white rounded-full italic'>{keyword}</li>
    ));
    
    const technologies = currentProject.technologies.map((technology, index) => {
        // Utiliser find pour trouver l'icône correspondante
        const matchedIcon = technologiesIcons.find(icon => technology === icon.name);
    
        // Vérifier si une correspondance a été trouvée
        if (matchedIcon) {
            const CurrentIcon = matchedIcon.icon
            const currentIconName = matchedIcon.name
            return (
                <li key={index} title={currentIconName}><CurrentIcon /></li>
            );
        }else {
            return null
        }
    });
    // N'afficher la div que s'il y a des partners
    const Partners = () => {
        if (currentProject.partners === 'None') {
        return null
        } else {
        return (
            <div>
                <h2 className='font-thin font-sans text-sm mb-8'>Partners</h2>
                <p>{currentProject.partners}</p>
            </div>
        )
        } 
    }

    // N'afficher la div que s'il y a des links
    const Links = () => {
        if (currentProject.links == null) {
        return null
        } else {
            return (
                <motion.div variants={childVariants} className='text-white'>
                    <h2 className='font-thin font-sans text-sm mb-8'>Links</h2>
                    <ul className='flex flex-wrap gap-16 mt-16'>
                        {projectLinks}
                    </ul>
                </motion.div>
            )
        } 
    }
    
    const ReadMore = () => {
        const isContentExist = projectsContent.find(project => currentProject.id === project.id);
        if (!isContentExist) {
            return null;
        } else {
            return (
                <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1, delay: 1.5}}} className='text-white text-sm absolute z-20 bottom-0 left-1/2 -translate-x-1/2 flex flex-col items-center gap-8'>
                    <p>Read more</p>
                    <svg width="4" height="26" viewBox="0 0 4 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 20.625L3 24V20.625H1Z" fill="white"/>
                        <path d="M3 0V20.625M3 20.625V24L1 20.625H3Z" stroke="white" strokeMiterlimit="16"/>
                    </svg>  
                </motion.div>
            )
        }
    }
    
    
    
  return (
    <div className='relative pb-40 w-full overflow-hidden'>
        <div className='absolute gradient-banner w-full h-full z-10'></div>
        <motion.div initial={{scale:1.2}} animate={{scale:1}} transition={{duration: 2, ease: "easeOut"}} className={`${currentProject.bgImg} absolute bg-cover bg-bottom w-full h-full z-0`}></motion.div>
        <div className='relative z-20'>
            <Navbar bgLight={false} />
            <motion.div variants={parentVariants} initial={'hidden'} animate={'visible'} className='max-w-[960px] mx-auto mt-40 mb-80 px-40 xl:px-16 2xl:px-0'>
                <MotionH1 
                contenthd={currentProject.name + '.'}
                bgLight={false}
                variants={childVariants}
                />
                <div className='flex justify-between flex-col xl:flex-row text-white mt-32 gap-32 xl:gap-80 mb-80 xl:mb-32'>
                    <motion.div className='flex flex-col gap-32'>
                        <motion.div variants={childVariants} className='flex gap-40'>
                            <div className='min-w-80'>
                                <h2 className='font-thin font-sans text-sm mb-8'>Release year</h2>
                                <p>{currentProject.year}</p>
                            </div>
                            <Partners />
                        </motion.div>
                        <motion.div variants={childVariants}>
                            <h2 className='font-thin font-sans text-sm mb-8'>Summary</h2>
                            <p className='max-w-[560px] xl:max-w-none'>{currentProject.sumLong}</p>
                        </motion.div>
                        
                    </motion.div>
                    <div className='xl:text-right flex flex-col gap-32 xl:min-w-[400px]'>
                        <motion.div variants={childVariants} className='max-w-[250px] xl:self-end'>
                            <h2 className='font-thin font-sans text-sm mb-8'>Technologies</h2>
                            <ul className='flex flex-row flex-wrap xl:justify-end gap-16 mt-16 font-light'>
                                {technologies}
                            </ul>
                        </motion.div>
                        <motion.div variants={childVariants} className='xl:self-end'>
                            <h2 className='font-thin font-sans text-sm mb-8'>Keywords</h2>
                            <ul className='flex flex-row flex-wrap xl:justify-end gap-8 mt-16 font-light'>
                                {keywords}
                            </ul>
                        </motion.div>
                    </div> 
                </div>
                <Links />  
            </motion.div> 
        </div>
        <ReadMore />
    </div>
  )
}

export default ProjectBanner