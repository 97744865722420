import imgWX from '../assets/images/wx96.webp'
import imgMem from '../assets/images/memoire.webp'
import imgMel from '../assets/images/melissa.webp'
import imgTB from '../assets/images/theboys.webp'
import imgST from '../assets/images/st.webp'
import imgLR from '../assets/images/lr.webp'
import imgCP from '../assets/images/cp.webp'
import imgRocky from '../assets/images/rocky.webp'
import imgRUX from '../assets/images/rux.webp'
import imgDS from '../assets/images/ds.webp'
import imgAvatar from '../assets/images/avatar.webp'
import imgPlp from '../assets/images/plportfolio.jpg'
import imgSWH from '../assets/images/swh.webp'

const projects = [
    {
      name: "Saint-Walhère d'Hemptinne",
      id: 'saint-walhere-hemptinne',
      bgImg: 'bg-bgSWH',
      year: 2024,
      sum: "Creation of a promotional poster and logo for the 50th anniversary of the event.",
      sumLong: "Creation of a promotional poster and logo in vector drawing for the 50th anniversary of the Entre-Sambre-et-Meuse Folkloric March in Hemptinne, Belgium. Close collaboration with the committee to develop multiple versions and achieve a final design reflecting the essence of this historic event.",
      img: imgSWH,
      featured: false,
      filters: ['other'],
      technologies: ['Illustrator', 'Photoshop', 'Notion'],
      keywords: ['Creativity', 'Graphic communication', 'Vector drawing', 'Folklore'],
      partners: 'None',
    },
    {
      name: "Pierre Lamotte's Portfolio",
      id: 'plamotte-portfolio',
      bgImg: 'bg-bgPlp',
      year: 2024,
      sum: "Creation of the visual aspect of an IT consultant's portfolio.",
      sumLong: "This project follows a request from a friend who needed a new portfolio for his IT consulting activity. I was thus able to create a prototype on Figma which he was able to develop subsequently.",
      img: imgPlp,
      featured: false,
      filters: ['web'],
      technologies: ['Figma', 'Photoshop'],
      keywords: ['Web', 'UX/UI', 'Wireframing', 'Portfolio'],
      partners: 'None',
      links: {
        figmaFile: {
          url: 'https://www.figma.com/proto/BcbRZMJpGkfzXbxEsyjInK/Portfolio-Pierre?type=design&node-id=172-2&t=vejAeVONeKQS3SOB-8&scaling=scale-down&page-id=0%3A1&starting-point-node-id=172%3A2&hide-ui=1',
          type: "figma",
          text: "Check the Figma prototype"
        }
      },
    },
    {
        name: 'Master Thesis',
        id: 'masterthesis',
        bgImg: 'bg-bgMasterThesis',
        year: 2023,
        sum: "How has transmedia impacted the world of board games?",
        sumLong: "This thesis explores the impact of transmedia on board games, examining current practices and emerging trends. It seeks to understand how transmedia influences game design, enhances storytelling, and suggests future research directions.",
        img: imgMem,
        featured: false,
        filters: ['transmedia'],
        technologies: ['Notion', 'Google Suite'],
        keywords: ['Board games', 'Transmedia', 'Emotions', 'Narration', 'Immersion'],
        partners: 'None',
        links: {
          productionFile: {
            url: 'https://www.williamblanchy.be/projets/M%C3%A9moire.pdf',
            type: "file",
            text: "Read my master thesis (FR)"
          }
        },
      },
      {
        name: 'WX-96',
        id: 'wx96',
        bgImg: 'bg-bgWX96',
        year: 2023,
        sum: "A narrative and immersive horror experience on the Internet and beyond.",
        sumLong: "A narrative and immersive horror experience on the Internet and beyond. WX‑96 is a collaborative transmedia project following the codes of ARGs (Alternate Reality Games) that can be found on social networks. It required many skills including photo and video editing, game design, team management, web development and many more.",
        img: imgWX,
        featured: true,
        filters: ['transmedia'],
        technologies: ['Notion', 'Google Suite', 'Airtable', 'Photoshop', 'Lightroom', 'Illustrator', 'Premiere Pro', 'Figma', 'Visual Studio Code', 'Musescore'],
        keywords: ['ARG', 'Horror', 'Community', 'Transmedia', 'Narrative', 'Social Networks'],
        partners: 'Tom Delfosse & Melih Selamet',
        links: {
          youtube: {
            url: 'https://youtu.be/BpaBXlwNcIM?si=rAxX0nOCCq-fYT0c',
            type: "youtube",
            text: "Watch the trailer"
          },
          productionFile: {
            url: 'https://www.williamblanchy.be/projets/WX96.pdf',
            type: "file",
            text: "Read the production file (FR)"
          }
        }
      },
      {
        name: 'The MELiSSA Project',
        id: 'melissa',
        bgImg: 'bg-bgMelissa',
        year: 2022,
        sum: "A promotional transmedia architecture for the European Space Agency (ESA).",
        sumLong: "The European Space Agency's MELiSSA project focuses on a closed-loop system for producing essentials from waste during space missions. We were tasked with creating a 3D support for conferences, envisioning a campaign to gamify and popularize the project, fostering a community through engaging narrative and selected media for science and space enthusiasts.",
        img: imgMel,
        featured: true,
        filters: ['transmedia'],
        technologies: ['Notion', 'Google Suite', 'Figma', 'Midjourney', 'Photoshop'],
        keywords: ['ESA', 'Transmedia', 'Space', 'Worldbuilding', 'Promotional'],
        partners: "The entire Master's class",
        links: {
          productionFile: {
            url:'https://www.williamblanchy.be/projets/MelissaProject.pdf',
            type: "file",
            text: "Read the production file (FR)"
          }
        }
      },
      {
        name: 'The Boys',
        id: 'theboys',
        bgImg: 'bg-bgTheBoys',
        year: 2022,
        sum: "The website of Vought International, the famous superhero agency.",
        sumLong: "This project involves creating a transmedia promotional strategy for the series The Boys on Prime Video. Comprising both online and offline components, the campaign starts before the first season's launch and extends beyond its broadcast.",
        img: imgTB,
        featured: true,
        filters: ['transmedia', 'web'],
        technologies: ['Google Suite', 'Figma', 'Photoshop', 'Illustrator'],
        partners: 'Dimitri Jansen, Hugo Delrez & Lucas Chardon',
        keywords: ['Series', 'Superheroes', 'Transmedia', 'Promotional'],
        links: {
          figmaFile: {
            url: 'https://www.figma.com/proto/hHfyvVNGZMfUjs7fUgSgJS/The-Boys-Project?type=design&node-id=0-1&scaling=min-zoom&starting-point-node-id=1%3A396',
            type: "figma",
            text: "Check the Figma prototype"
          }
        }
      },
      {
        name: 'Rocky',
        id: 'rocky',
        bgImg: 'bg-bgRocky',
        year: 2022,
        sum: "Recomposition of the musical theme of Rocky Balboa's training.",
        sumLong: "This project involves reimagining the iconic training theme of Rocky Balboa from the first film. The entire composition is original and was created using Musescore and Adobe Audition software.",
        img: imgRocky,
        featured: false,
        filters: ['other'],
        technologies: ['Musescore', 'Audition'],
        keywords: ['Musical', 'Composition', 'Rock', 'Theme', 'Rocky'],
        partners: 'None',
        links: {
          youtube: {
            url: 'https://youtu.be/DyqYe78VvgM',
            type: "youtube",
            text: "Listen to the final composition"
          }
        }
      },
      {
        name: 'Avatar Analysis',
        id: 'avatar',
        bgImg: 'bg-bgAvatar',
        year: 2021,
        sum: "Analysis of the transmedia universe of Avatar the Last Airbender.",
        sumLong: "Analysis of the transmedia universe of Avatar the Last Airbender through the scholarly articles of Henry Jenkins and Sarah Sepulchre. Originating with the animated series in 2005, this universe stands out for its powerful symbols, extended across various media to enhance audience engagement.",
        img: imgAvatar,
        featured: false,
        filters: ['transmedia'],
        technologies: ['Notion', 'Google Suite', 'Medium'],
        keywords: ['Transmedia', 'Analysis', 'Avatar', 'Jenkins', 'Sepulchre'],
        partners: 'None',
        links: {
          medium: {
            url: 'https://williamblanchy.medium.com/the-transmedia-case-of-avatar-the-last-airbender-09b4b9431fd4',
            type: "medium",
            text: "Read the whole analysis (FR)",
          }
        }
      },
      {
        name: 'LudoRoll',
        id: 'ludoroll',
        bgImg: 'bg-bgLudoRoll',
        year: 2021,
        sum: "The website that helps you enter the world of board games.",
        sumLong: "The website that helps you enter the world of board games. Designed and developed during my final year of bachelor's studies as part of my thesis, the goal was to merge passion and work within a unified project.",
        img: imgLR,
        featured: false,
        filters: ['web'],
        technologies: ['Google Suite', 'Figma', 'Visual Studio Code', 'ReactJS', 'TailwindCSS', 'Illustrator', 'Medium'],
        keywords: ['Board games', 'Web', 'Tool', 'Beginners'],
        partners: 'None',
        links: {
          website: {
            url: 'https://www.williamblanchy.be/projets/tfe',
            type: "website",
            text: "Check the website (FR)"
          },
          medium: {
            url: 'https://medium.com/@williamblanchy/tfe-les-débuts-268f2f907d39',
            type: "medium",
            text: "Read the case study (FR)"
          }
        }
      },
      {
        name: 'SpaceTour',
        id: 'spacetour',
        bgImg: 'bg-bgSpaceTour',
        year: 2020,
        sum: "A website helping children discover gravity while having fun.",
        sumLong: "A website helping children discover gravity while having fun. It features a mini game where Newton introduces the laws of gravity in space using planets from the solar system. The challenge was to successfully replicate a gravitational field using Javascript.",
        img: imgST,
        featured: false,
        filters: ['web'],
        technologies: ['Google Suite', 'Medium', 'Figma', 'Visual Studio Code', 'Javascript'],
        keywords: ['Space', 'Web', 'Kids', 'Learning', 'Gravity'],
        partners: 'Tom Delfosse, Elie Durieux, Ludovic Decuyper, Camille Mouvet & Guillaume Pihard',
        links: {
          website: {
            url: 'https://www.williamblanchy.be/projets/spacetour',
            type: "website",
            text: "Check the website (FR)"
          },
          medium: {
            url: 'https://eliedurieux.medium.com/space-tour-rayonner-parmi-les-étoiles-1-5-8cb0c586dab3',
            type: "medium",
            text: "Read the case study (FR)"
          }
        }
      },
      {
        name: 'Caps Phabet',
        id: 'capsphabet',
        bgImg: 'bg-bgCapsPhabet',
        year: 2020,
        sum: "Creation of an original photographic alphabet.",
        sumLong: "This project involves creating an original photographic alphabet using bottle caps. The goal was to stimulate creativity to produce something unique and personal. The idea originated during a drunken evening where we had enjoyed several special beers.",
        img: imgCP,
        featured: false,
        filters: ['other'],
        technologies: ['Photoshop', 'Visual Studio Code'],
        keywords: ['Creativity', 'Capsules', 'Alphabet', 'Beer'],
        partners: 'None',
        links: {
          website: {
            url: 'https://www.williamblanchy.be/projets/capsphabet',
            type: "website",
            text: "Check the website"
          }
        }
      },
      {
        name: 'Design Switch',
        id: 'designswitch',
        bgImg: 'bg-bgDesignSwitch',
        year: 2020,
        sum: "Redesign of the HEAJ website based on the graphic charter of that of Sea of ​​​​Thieves.",
        sumLong: "Redesign of the HEAJ website based on the graphic charter of that of Sea of ​​​​Thieves. This project provided an opportunity to learn how to create a user interface (UI) kit. We chose the Sea of Thieves website for its distinctive and original design, adding a playful element to the challenge.",
        img: imgDS,
        featured: false,
        filters: ['web'],
        technologies: ['Figma'],
        keywords: ['Redesign', 'Web', 'HEAJ', 'Sea of Thieves'],
        partners: 'Tom Delfosse',
        links: {
          website: {
            url: 'https://www.williamblanchy.be/projets/designswitch',
            type: "website",
            text: "Check the project (FR)"
          }
        }
      },
      {
        name: 'Rethinking UX',
        id: 'rux',
        bgImg: 'bg-bgRUX',
        year: 2019,
        sum: "Review the ergonomics and accessibility of the 2019 KIKK Festival website.",
        sumLong: "Review the ergonomics and accessibility of the 2019 KIKK Festival website. This project encompassed various stages, from developing user tests to crafting a case study, including the design of paper prototypes.",
        img: imgRUX,
        featured: false,
        filters: ['web'],
        technologies: ['Visual Studio Code'],
        keywords: ['Web', 'UX', 'Analysis', 'KIKK', 'Wireframing'],
        partners: 'None',
        links: {
          website: {
            url: 'https://www.williamblanchy.be/projets/rux',
            type: "website",
            text: "Check the project (FR)"
          }
        }
      },
]

export default projects