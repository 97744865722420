import React from 'react';
import { Link } from 'react-router-dom';

const ProjectThumbnail = ({ src, title, year, sum, id }) => {
    const url = '/portfolio/'+id
    return(
        <Link reloadDocument to={url} className='relative flex flex-col z-10 max-w-[280px] 2xl:max-w-[320px] min-w-[240px] rounded-t-xl overflow-hidden text-left'>
            <div className='xl:h-[276px] w-full overflow-hidden'>
                <img className='h-[240px] xl:h-[276px] w-full object-cover transition-transform transform hover:scale-110' src={src} alt={`${title} Thumbnail`}></img>
            </div>      
            <div className='flex justify-between my-[12px]'>
                <h3 className='text-2xl font-semibold'>{title}</h3>
                <p className='text-xl pt-4'>{year}</p>
            </div>
            <p className='font-normal'>{sum}</p>
            <p className='w-max text-gold font-semibold hover:underline mt-16 pr-24 bg-iconArrow bg-no-repeat bg-right'>Read more</p>
        </Link>
    )
}

export default ProjectThumbnail
