import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MotionH1 from '../components/H1';
import ProjectFilter from '../components/ProjectFilter';
import ProjectList from '../components/ProjectList';
import projects from '../../data/projects';
import { Helmet } from 'react-helmet-async';

const Portfolio = () => {
  const [activeFilter, setActiveFilter] = useState('all');


  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <div className='bg-grey-dark text-white font-sans text-base'>
      <Helmet>
        <title>William Blanchy | Portfolio</title>
      </Helmet>
      <Navbar bgLight={false} />
      <div className='mx-auto max-w-[1280px] my-16 md:mt-40 md:mb-160'>
        <MotionH1
          content={'My '}
          contenthd={'Portfolio.'}
          bgLight={false}
          textAlignCenter={true}
          initial={{ opacity: 0, y: 24 }}
          animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
        />
        <ProjectFilter activeFilter={activeFilter} handleFilterClick={handleFilterClick} />
        <ProjectList filteredProjects={projects} activeFilter={activeFilter} />
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
