import React from 'react'
import MotionH2 from './H2'
import technologiesIcons from '../../data/technologiesIcons'
import { motion } from 'framer-motion'

const SectionAbout = () => {

    // Animation des enfants et parents
    const childVariants = {
        visible: {opacity: 1, x: 0, transition:{duration:0.5}},
        hidden: {opacity: 0, x: -24}
    }
    const parentVariants = {
        visible: {transition: {staggerChildren: 0.2}},
        hidden: {transition: {staggerChildren: 0.2}}
    }

    const technologies = technologiesIcons.map((techIcon, index) => {
        
        const CurrentIcon = techIcon.icon ;
        const currentIconName = techIcon.name
        return <li key={index} title={currentIconName}><CurrentIcon /></li>

    });

    const softSkillsList = ["Creativity", "Logic", "Curiosity", "Autonomy", "Sociability", "Organization", "Meticulousness", "Versatility"];
    const softSkills = softSkillsList.map((skill, index) => {

        return <li key={index} className='py-8 px-16 border text-base border-white rounded-full italic'><p>{skill}</p></li>
    })

    const hobbiesList = ["Board games", "Video games", "Cinema", "Photography", "Football", "Traveling", "Museums"];
    const hobbies = hobbiesList.map((hobby, index) => {

        return <li key={index} className='py-8 px-16 border border-white rounded-full italic'>{hobby}</li>
    })

  return (
    <div className='gradient-grey-bg w-full'>
        <div className='mx-auto max-w-[1280px] px-16 pt-40 xl:pt-80 pb-160'>
            <motion.div variants={parentVariants} initial={'hidden'} whileInView={'visible'} viewport={{once:true}} className='xl:mx-80'>
                <MotionH2 variants={childVariants} content="About me" />
                <motion.div className='mt-32 flex flex-col md:flex-row flex-wrap gap-80'>
                    <motion.div variants={childVariants} className='max-w-[400px] flex flex-col gap-8'>
                        <h3 className='text-sm font-light'>Abstract</h3>
                        <p>I have followed a diverse academic path, acquiring various skills in the field of digital technology. This journey has allowed me to work on different aspects of projects, including the creation of a cohesive graphic universe, the importance of storytelling and code, media and SEO optimization. I collaborated with profiles ranging from similar to mine to more diverse ones. This experience has made me comfortable in both collaborative environments and independent projects.</p>
                        <p>Throughout my various accomplishments, I discovered that what I enjoy the most is taking a simple idea and developing it into something tangible, witnessing a project evolve, build, and take shape over time. My versatility, curiosity, and critical thinking have consistently guided me, enabling me to move forward with clarity and precision.</p>
                    </motion.div>
                    <motion.div variants={childVariants} className='md:max-w-[200px]'>
                        <h3 className='text-sm font-light mb-16'>Technologies</h3>
                        <ul className='flex gap-16 flex-wrap'>{technologies}</ul>
                    </motion.div>
                    <motion.div className='flex flex-col md:flex-row 2xl:flex-col gap-32 2xl:max-w-[320px]'>
                        <motion.div variants={childVariants}>
                            <h3 className='text-sm font-light mb-16'>Soft Skills</h3>
                            <ul className='flex flex-wrap flex-row gap-8 font-light'>{softSkills}</ul>
                        </motion.div>
                        <motion.div variants={childVariants}>
                            <h3 className='text-sm font-light mb-16'>Hobbies</h3>
                            <ul className='flex flex-wrap flex-row gap-8 font-light'>{hobbies}</ul>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
            
        </div>
        
    </div>
  )
}

export default SectionAbout