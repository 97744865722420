import React from 'react';
import { motion } from 'framer-motion';
import ProjectThumbnail from './ProjectThumbnail';

const ProjectList = ({ filteredProjects, activeFilter }) => {
  const childVariants = {
    visible: { opacity: 1, y: 0, x: 0, scale: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 80, x: -80, scale: 0.8 },
  };
  const parentVariants = {
    visible: { transition: { staggerChildren: 0.2 } },
    hidden: { transition: { staggerChildren: 0.2 } },
  };


  const projectList = filteredProjects.map((project, index) => (
    <motion.li key={index} className={`mx-16 my-40 2xl:mx-32 xl:my-64  ${project.filters.includes(activeFilter) || activeFilter === "all" ? 'block' : 'hidden'}`} variants={childVariants}>
      <ProjectThumbnail
        title={project.name}
        src={project.img}
        year={project.year}
        sum={project.sum}
        id={project.id}
      />
    </motion.li>
  ));

  return (
    <motion.ul
      className='overflow-y-hidden px-40 flex xl:flex-wrap xl:justify-center mt-16 mb-40 xl:mb-80'
      variants={parentVariants}
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ once: true }}
    >
      {projectList}
    </motion.ul>
  );
};

export default ProjectList;